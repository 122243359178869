const links = {
  linkedin:
    "https://www.linkedin.com/in/raul-aguirre-7aaa3a178?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BYF%2FxJSN8RY2JypU3F4%2B9TQ%3D%3D",
  twitter: "https://twitter.com/RandomRaul34",
  github: "https://github.com/ateBitDev?tab=repositories",
}

const quotes = {
  quote:
    '"Insanity is doing the same thing over and over and expecting different results."',
}

const description = {
  title: "Hello, I'm Raul.",
  body: "I'm a front end engineer living on the central coast of California.",
}

const Language = {
  links,
  quotes,
  description,
}

export default Language
